import React from "react";
import { useMemo, useEffect } from "react";
import { Vector3, Box3 } from "three";
import { useGLTF } from "@react-three/drei";
import { useThree, useFrame } from "@react-three/fiber";
import { Html, useProgress } from "@react-three/drei";

export const Loader = () => {
  const progress = useProgress((state) => state.progress);
  return (
    <Html center>
      <h1 aria-label="Loader">{Math.trunc(progress)}%</h1>
    </Html>
  );
};

const Model = ({ model }) => {
  const sceneUrl = model;
  console.log("model", model);

  const myMesh = React.useRef();

  const { camera } = useThree();
  const scene = useGLTF(sceneUrl).scene;

  const { convertedScene, box, position } = useMemo(() => {
    const convertedScene = scene.clone();
    convertedScene.traverse((obj) => {
      if (obj.isMesh) {
        obj.layers.enable(2);
      }
    });

    const box = new Box3().setFromObject(convertedScene, 0.01);
    const center = new Vector3();
    box.getCenter(center);
    const size = new Vector3(10, 10, 10);
    box.getSize(size);
    const position = new Vector3().copy(center.clone().multiplyScalar(-1));
    return { convertedScene, box, position };
  }, [scene]);

  useEffect(() => {
    const size = new Vector3(10, 10, 10);
    box.getSize(size);
    const maxSize = Math.max(size.x, size.y, size.z);
    camera.position.set(-maxSize, 0, 0);
    camera.rotation.set(0, 0, 0);
    camera.updateMatrixWorld();
    camera.updateProjectionMatrix();
  }, [box, camera]);

  useFrame(({ clock }) => {
    const a = clock.getElapsedTime() / 3;
    myMesh.current.rotation.y = a;
  });

  return (
    <primitive
      ref={myMesh}
      name="Model"
      object={convertedScene}
      position={position}
      rotation={[0, 4.5, 0.01]}
      scale={1}
    />
  );
};

export default Model;
