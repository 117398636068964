import { Suspense } from "react";
import { Canvas } from "@react-three/fiber";
import "./App.css";
import { OrbitControls } from "@react-three/drei";

import Lights from "./components/Camera/Lights";
import Model, { Loader } from "./components/Model";

function App() {
  const URL = window.location.search.split("?model=")[1];

  return (
    <div className="Container" style={{ width: "100%", height: "100vh" }}>
      <Canvas>
        <Lights />
        <Suspense fallback={<Loader />}>
          <Model model={URL} />
        </Suspense>

        <OrbitControls
          enablePan={false}
          enableDamping={false}
          enableZoom
          enableRotate
          target={[0, 0, 0]}
        />
      </Canvas>
    </div>
  );
}

export default App;
